import React from "react"
import { Link } from "gatsby"

import "./index.css"

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Fullstack Developer Los Angeles, CA | Kenneth Taylor" />
    <section id="home">
      <div class="container">
        <div>
          <h1>Here to help you improve your digital presence</h1>
          <p>I'm a Full Stack Developer with a broad range of skills and expertise in Search Engine Optimization and all digital marketing fields.</p>
          <div>
            <Link
              class="solid-btn"
              href="https://calendly.com/kennethgt/consultation"
              target="_blank"
            >
              Schedule A Consultation
            </Link>
          </div>
        </div>
      </div>
    </section>
    {/* <section id="about">
      <div className="container">
        <h2>About</h2>
        <p>My name is Kenneth Taylor. I’m an entrepreneur & Life Coach. I'm passionate about solving problems and helping others reach their goals.</p>
        <p>My work currently consists of managing a boutique development agency and managing ongoing coaching clients. I find this balancing act to be immensely resourceful in terms of my learning as a coach, as well as for keeping up with the rapid expansion of technology.</p>
        <p>Whether you're a business owner looking to get started on an app or a development project, an individual looking for guidance on how to reach your goals, or just wanting to say hi, shoot me a message and let's work together.</p>
      </div>
    </section> */}
  </Layout>
)
export default IndexPage
